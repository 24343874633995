import React from "react";
import { Row, Col } from "antd";
import loadable from "@loadable/component";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import {
  emailValidationRegExp,
  phoneValidationRegExp,
  formItemTypes,
} from "../../../components/GatedAsset/constants";
import ogImage from "../../../images/global_assets/og-image.png";
import { gatedSectionContactless } from "../../../data/landing/gated-v2";
import { lineOfBusiness, zipValidationRegExp } from "../../../constants";

const GatedForm = loadable(() =>
  import("../../../components/CustomGated/GatedForm")
);
const Image = loadable(() =>
  import("../../../components/ImageQuerys/LargeFeaturesImages")
);
// const GatedCTA = loadable(() =>
//   import("../../../components/CustomGated/GatedCTA")
// );
const GatedSpecial = loadable(() =>
  import("../../../components/CustomGated/GatedSpecial")
);
const GatedSellingPoint = loadable(() =>
  import("../../../components/CustomGated/GatedSellingPoint")
);

const formDataToSend = ["firstname", "email", "phone", "company", "get_demo"];

const subtext = "";
const blockList = [
  "Content feature 1",
  "Content feature 2",
  "Content feature 3",
  "Content feature 4",
];

const data = [
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "First Name",
    name: "firstName",
    rules: [
      {
        required: true,
        message: "Please enter your first name",
        min: 2,
      },
    ],
    placeholder: "Your First Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Last Name",
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Please enter your last name",
        min: 2,
      },
    ],
    placeholder: "Your Last Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "email",
    label: "Email",
    name: "email",
    rules: [
      {
        required: true,
        message: "Please enter a valid email",
        pattern: emailValidationRegExp,
      },
    ],
    placeholder: "e.g. john_doe@mybusiness.com",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Business Name",
    name: "company",
    rules: [
      {
        required: true,
        message: "Please enter your business name",
        min: 3,
      },
    ],
    placeholder: "e.g. MyBusiness Inc. ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "tel",
    label: "Business Phone",
    name: "phone",
    rules: [
      {
        required: true,
        message: "Please enter your phone",
        pattern: phoneValidationRegExp,
      },
    ],
    placeholder: "e.g. (555) 555-1243 ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: null,
    label: "Zip Code",
    name: "zip",
    rules: [
      {
        required: true,
        message: "Please enter your zip code",
        min: 4,
        pattern: zipValidationRegExp,
      },
      // () => ({
      //   validator(_, value) {
      //     const address = zipCodesMap.get(value);
      //     if (address && address.zip_code) {
      //       return Promise.resolve();
      //     }
      //     return Promise.reject("Please enter a valid zip code");
      //   },
      // }),
    ],
    placeholder: "e.g. 10001",
    visible: true,
  },
  {
    itemType: formItemTypes.CHECKBOX,
    itemSubType: null,
    label: "Yes, I’d like a demo of SpotOn Restaurant point-of-sale.",
    name: "get_demo",
    visible: true,
  },
];

const ContactlessTechSurvey = () => {
  return (
    <LpLayout phoneNumber="+18775594225" phoneText="(877) 559-4225">
      <SEO
        title="SpotOn White Paper | Contactless Technology Survey"
        description="Discover the stats behind the customer trends in large venues, restaurants, and retail stores that are here to stay in the new normal."
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <Row
        className="gate-heading-v1 max-w-1440 "
        gutter={[32, 32]}
        align="middle"
        justify="center"
      >
        <Col lg={12}>
          <div className="lg:max-w-[430px] xl:translate-x-[25%]">
            <h1
              id="modern"
              className="big-gated-intro-full cta-light whitespace-pre-line"
            >
              Contactless {`\n`} technology survey
              <span className="blue_dot">.</span>
            </h1>
            <p className="gated-intro-para text-black-600 text-[20px]">
              In the report conducted October 19 – 20, 2020, SpotOn (formerly
              Appetize) surveyed 2,081 people ranging from 18 to 70 in the
              United States on the impact contactless technology has on their
              decisions and experiences at restaurants, stadiums, and holiday
              shopping during COVID-19—as well as their preferences post
              COVID-19.
            </p>
          </div>
        </Col>
        <Col lg={12}>
          <div className="gated-form-cont-v3">
            <GatedForm
              pageTitle="Title of the downloadable content"
              pageSubtext={subtext}
              formTitle="Download your free whitepaper."
              formId="c3221775-397e-41fe-ac5f-f822c36d96df"
              formItems={data}
              formDataToSend={formDataToSend}
              featureTitle="What you get"
              featureSubtext="Fill out the form to get your free download today."
              blockList={blockList}
              topImg="asset-download.png"
              thankYouPath="/gated-assets/contactless-technology-survey/thank-you"
              gatedCampaign="Contactless Technology Survey"
              lineOfBusiness={lineOfBusiness.VENUES}
            />
          </div>
          <div className="form-bg">
            <Image imageName="Form-Bg.png" />
          </div>
        </Col>
        <Col lg={24}>
          <Image className="banner-text" imageName="banner-text.png" />
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <Col lg={24} className="w-full">
          <GatedSpecial sectionData={gatedSectionContactless} />
        </Col>
        <Col lg={24}>
          <GatedSellingPoint
            mainPoint="What customers think about contactless"
            imageName="contactless-tech-survey-b.png"
            withCta={false}
            imgClass="mt-32"
            subtext="Does contactless technology influence your customers’ purchasing habits? In this survey, we talked to more than 2,000 people who go to restaurants, large venues, and retail stores to discover how consumers feel about payment methods during COVID."
          />
        </Col>
      </Row>
    </LpLayout>
  );
};

export default ContactlessTechSurvey;
